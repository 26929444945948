import React, { useContext } from "react";
import { navigate } from "gatsby";
import { processLanguage } from "../util/functions";
import { ConsultationContext } from "../context/Consultation";
import { AuthContext } from "../context/Auth";
import { AnalyticsContext } from "../context/Analytics";

import i from "../staticAssets/user.png";

function AstroCardNew({ data, astrologerId, setStatus, config}) {
  const { initiateCall, initiateChat } = useContext(ConsultationContext) || {
    initiateCall: () => {},
    initiateChat: () => {},
  };
  const { user, setshowLogin } = useContext(AuthContext) || {
    user: {},
    setshowLogin: () => {},
  };
  const { gtmCustomEvent } = useContext(AnalyticsContext) || {
    getCustomEvent: () => {},
  };

  return (
    <>
      {data?.widgetType === 1 &&
      (data?.data?.isConnectModeChat || data?.data?.isConnectModeCall) ? (
        <div className="flex flex-col w-[80vw] md:w-[26vw] justify-between items-start mr-10 mb-10 rounded-lg overflow-hidden bg-white shadow-lg p-6">
          <a className="w-full relative cursor-pointer" onClick={() => {navigate("/astrologers/" + data?.data?.user, {
                  state: data?.data?.user,
                });}}>
            <div className="absolute top-4 right-4 bg-white rounded-full flex border border-[#F98A28] px-1 items-center">
              <h5 className="text-xs text-[#ff7000] flex items-center justify-between">
                {data?.data?.rating}{" "}
              </h5>
              <svg
                className="ml-1"
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5 0.5L6.73494 4.30026L10.7308 4.30041L7.49817 6.64924L8.73282 10.4496L5.5 8.101L2.26718 10.4496L3.50183 6.64924L0.269189 4.30041L4.26506 4.30026L5.5 0.5Z"
                  fill="#F98A28"
                />
              </svg>
            </div>
            <div className="w-full h-[70vw] md:h-[20vw] bg-gray-100/80 rounded-lg flex justify-center">
              {data?.data?.imgUrl ? (
                <img
                  loading="lazy"
                  className="object-cover object-center  h-[70vw] md:h-[20vw] w-auto bg-gray-100/50"
                  width="100" height="100"
                  alt={data?.data?.name}
                  src={data?.data?.imgUrl}
                />
              ) : (
                <img
                  loading="lazy"
                  src={i}
                  className="object-contain w-full h-full"
                  width="100" height="100"
                  alt={data?.data?.name}
                />
              )}
            </div>
          </a>
          <a className="flex flex-col worksans mt-2 cursor-pointer" onClick={() => {navigate("/astrologers/" + data?.data?.user, {
                  state: data?.data?.user,
                });}}>
            <h3 className="text-black text-lg block">{data?.data?.name} </h3>
            <span className="block">
              {"Experience - " +
                (data?.data?.experience?.years || "1") +
                "+ years"}
            </span>
            <h3 className="block">{data?.data?.subHeading}</h3>
            <h3 className="block">{processLanguage(data?.data?.languages)}</h3>
          </a>
          <div className="flex justify-between w-full gap-4 mt-2">
            {data?.data?.isConnectModeCall &&
              (data?.data?.user === astrologerId ? (
                <button
                  disabled
                  className="bg-gradient-to-r from-[#FEB458] to-[#ED9108] w-full rounded-xl text-white text-xs lg:text-base shadow object-contain p-1"
                >
                  <div className="flex flex-col items-center">
                    <h2 className="p-2">Busy</h2>
                  </div>
                </button>
              ) : data?.data?.status === 1 ? (
                <button
                  className="bg-[#87BD36] w-full rounded-xl text-white text-xs lg:text-base shadow object-contain p-0.5"
                  onClick={async () => {
                    if (user?.name !== undefined) {
                      gtmCustomEvent("call_chat");
                      let s = await initiateCall(
                        data?.data?.user,
                        data?.data?.imgUrl
                      );
                      if (s !== 1) {
                        setStatus(data?.data?.user, s);
                      }
                    } else {
                      setshowLogin(true);
                    }
                  }}
                >
                  <div className="flex flex-col items-center">
                    <h4>Call</h4>
                    <div className="flex">
                      <h4 className="line-through">
                      {data?.data.newUserSinglePriceConsultAvailable && config?.flatPriceOfferCampaign ? '' : (user?.currencyType === "usd" ? "$" : "₹") +
                            (user?.currencyType === "usd"
                              ? data?.data?.actualCallRate?.usd
                              : data?.data?.actualCallRate?.inr) +
                          ","}
                      </h4>{" "}
                      <h4 className="ml-1">
                      {data?.data.newUserSinglePriceConsultAvailable  && config?.flatPriceOfferCampaign ? config.buttonText : (user?.currencyType === "usd" ? "$" : "₹") +
                            (user?.currencyType === "usd"
                              ? data?.data?.callRate?.usd
                              : data?.data?.callRate?.inr) +
                            "/Min"}
                      </h4>
                    </div>
                  </div>
                </button>
              ) : data?.data?.status === 2 ? (
                <button
                  disabled
                  className="bg-gradient-to-r from-[#FEB458] to-[#ED9108] w-full rounded-xl text-white text-xs lg:text-base shadow object-contain p-1"
                >
                  <div className="flex flex-col items-center">
                    <h2 className="p-2">Busy</h2>
                  </div>
                </button>
              ) : (
                <button
                  disabled
                  className="bg-gray-100 w-full rounded-xl text-gray-400 text-xs lg:text-base shadow object-contain p-1"
                >
                  <div className="flex flex-col items-center">
                    <h4>Call</h4>
                    <div className="flex">
                      <h4 className="line-through">
                        {(user?.currencyType === "usd" ? "$" : "₹") +
                          (user?.currencyType === "usd"
                            ? data?.data?.actualCallRate?.usd
                            : data?.data?.actualCallRate?.inr) +
                          ","}
                      </h4>{" "}
                      <h4 className="ml-1">
                        {(user?.currencyType === "usd" ? "$" : "₹") +
                          (user?.currencyType === "usd"
                            ? data?.data?.callRate?.usd
                            : data?.data?.callRate?.inr) +
                          "/Min"}
                      </h4>
                    </div>
                  </div>
                </button>
              ))}
            <div className="w-2 h-vh"></div>
            {data?.data?.isConnectModeChat &&
              (data?.data?.user === astrologerId ? (
                <button
                  disabled
                  className="bg-gradient-to-r from-[#FEB458] to-[#ED9108] w-full rounded-xl text-white text-xs lg:text-base shadow object-contain p-1"
                >
                  <div className="flex flex-col items-center">
                    <h2 className="p-2">Busy</h2>
                  </div>
                </button>
              ) : data?.data?.status === 1 ? (
                <button
                  className="bg-[#87BD36] w-full rounded-xl text-white text-xs lg:text-base shadow object-contain p-1"
                  onClick={async () => {
                    if (user?.name !== undefined) {
                      gtmCustomEvent("call_chat");
                      let s = await initiateChat(
                        data?.data?.user,
                        data?.data?.imgUrl
                      );
                      if (s !== 1) {
                        setStatus(data?.data?.user, s);
                      }
                    } else {
                      setshowLogin(true);
                    }
                  }}
                >
                  <div className="flex flex-col items-center">
                    <h4>Chat</h4>
                    <div className="flex">
                      <h4 className="line-through">
                      {data?.data.newUserSinglePriceConsultAvailable  && config?.flatPriceOfferCampaign ? '' :(user?.currencyType === "usd" ? "$" : "₹") +
                            (user?.currencyType === "usd"
                              ? data?.data?.actualCallRate?.usd
                              : data?.data?.actualCallRate?.inr) +
                            ","}
                      </h4>{" "}
                      <h4 className="ml-1">
                      {data?.data.newUserSinglePriceConsultAvailable  && config?.flatPriceOfferCampaign  ? config.buttonText : (user?.currencyType === "usd" ? "$" : "₹") +
                            (user?.currencyType === "usd"
                              ? data?.data?.callRate?.usd
                              : data?.data?.callRate?.inr) +
                            "/Min"}
                      </h4>
                    </div>
                  </div>
                </button>
              ) : data?.data?.status === 2 ? (
                <button
                  disabled
                  className="bg-gradient-to-r from-[#FEB458] to-[#ED9108] w-full rounded-xl text-white text-xs lg:text-base shadow object-contain p-1"
                >
                  <div className="flex flex-col items-center">
                    <h2 className="p-2">Busy</h2>
                  </div>
                </button>
              ) : (
                <button
                  disabled
                  className="bg-gray-100 w-full rounded-xl text-gray-400 text-xs lg:text-base shadow object-contain p-1"
                >
                  <div className="flex flex-col items-center">
                    <h4>Chat</h4>
                    <div className="flex">
                      <h4 className="line-through">
                        {(user?.currencyType === "usd" ? "$" : "₹") +
                          (user?.currencyType === "usd"
                            ? data?.data?.actualCallRate?.usd
                            : data?.data?.actualCallRate?.inr) +
                          ","}
                      </h4>{" "}
                      <h4 className="ml-1">
                        {(user?.currencyType === "usd" ? "$" : "₹") +
                          (user?.currencyType === "usd"
                            ? data?.data?.callRate?.usd
                            : data?.data?.callRate?.inr) +
                          "/Min"}
                      </h4>
                    </div>
                  </div>
                </button>
              ))}
          </div>
        </div>
      ) : data?.widgetType === 2 ? (
        <></>
      ) : (
        <></>
      )}
    </>
  );
}

export default AstroCardNew;
