import React, { useState, useEffect } from "react";
import { getTestimonials } from "../../services/apiServices";

const emo = ["❤️", "😇", "👍", "✌️", "👌", "😊", "😙"];

function Sec6() {
  const [testimonials, settestimonials] = useState([]);

  useEffect(() => {
    let f = async () => {
      let res = await getTestimonials(1, 7);
      if (res?.statusCode === 200) {
        settestimonials([...res.payLoad.testimonials]);
      }
    };
    f();
  }, []);
  return (
    <div className="bg-gray-100/50 my-10">
      <div className="container mx-auto py-10">
        <h3 className="text-black text-[25px] md:text-[50px] font-bold playfair text-center">
          Customer Reviews
        </h3>
        <div className="flex overflow-x-scroll pb-20 mt-2 hide-scroll-bar scrollbar-hide mt-10">
          <div className="flex flex-nowrap ml-2">
            {testimonials.map((v, i) => {
              return (
                <div
                  className="w-[70vw] md:w-[25vw] bg-white rounded-xl shadow-2xl mx-6 relative mt-10 flex flex-col justify-between"
                  key={i}
                >
                  <div className="absolute -top-6 left-4 rounded-full h-12 w-12 flex items-center justify-center p-2 bg-white border border-gray-200">
                    {emo[i]}
                  </div>
                  <h5 className="worksans mx-8 mt-10 text-sm">
                    {v?.feedback ?? ""}
                  </h5>
                  <div className="flex my-4 mx-8 items-center gap-2">
                    <div className="h-12 w-12 rounded-full bg-gradient-to-r from-blue-100 to-orange-100 flex items-center justify-center font-semibold text-blue-400 worksans">
                      {(v?.name ?? "").slice(0, 1).toUpperCase()}
                    </div>
                    <div className="flex flex-col worksans">
                      <h6 className="text-black">{v?.name}</h6>
                      <h6 className="text-gray-400 text-sm">
                        {v?.location ?? ""}
                      </h6>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sec6;
