import React, { useEffect, useState } from 'react'
import AstroCardNew from '../AstroCardNew';
import {navigate} from 'gatsby';
import { getWebConfig } from '../../services/apiServices';

function Sec2({widgets, setStatus, astrologerId}) {
  const [config, setConfig] = useState()
  const [host, sethost] = useState("");

  useEffect(() => {
    sethost(window.location.host);
  }, []);
  useEffect(() => {
    const callConfig = async () => {

      try {
        let configDataResponse;
        const specialHosts = [
          'localhost:8000',
          process.env.GATSBY_JIO_BASE,
          process.env.GATSBY_MEESHO_BASE,
          process.env.GATSBY_NAMAH_BASE,
          process.env.GATSBY_LOKAL_BASE,
          process.env.GATSBY_BODHI_BASE,
          
        ];
  
        if (specialHosts.includes(host)) {
          // configDataResponse = await getWebConfig(host === 'localhost:8000' || 'bodhi.web' ? 'web' : host );
          configDataResponse = await getWebConfig( host );
          
        } else {
          configDataResponse = await getWebConfig(host );

          // configDataResponse = await getWebConfig(host === 'localhost:8000' || 'bodhi.web' ? 'web' : host);
        }
  
        if (configDataResponse) {
          setConfig(configDataResponse?.payLoad);
        }
      } catch (error) {
        console.error("Error fetching config data:", error);
      }
    };
  
    callConfig();
  }, [host]);
  return (
    <div className="container mx-auto flex flex-col justify-center">
            <h1 className="text-black text-[25px] md:text-[50px] font-bold playfair text-center">
              Top Astrologers
            </h1>

            <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
              <div className="flex flex-nowrap lg:ml-10 md:ml-20 ml-2">
                {widgets.slice(0, 5)?.map((v, i) => {
                  return (
                    <AstroCardNew
                      key={i}
                      data={v}
                      astrologerId={astrologerId}
                      setStatus={setStatus}
                      config={config}
                    />
                  );
                })}
              </div>
            </div>
            <div className="w-full flex justify-center">
              <button
                className="w-3/4 md:w-1/3 border border-[#FB5B1D] py-2 rounded-xl bg-white shadow-md text-[#FB5B1D] tracking-wide worksans text-base md:text-lg"
                onClick={() => {
                  navigate("/consultAstro");
                }}
              >
                View All Astrologers
              </button>
            </div>
          </div>
  )
}

export default Sec2